import * as React from 'react'

import { classNames } from '@common/utils/helpers'
import styled from '@emotion/styled'
import { useCombinedRefs } from '@common/utils/use-hooks'

/**
 * @todo create new component with radio group
 */
export interface RadioButtonProps extends React.ComponentPropsWithRef<'input'> {
  className?: string | undefined
  name?: string
  caption?: string | React.ReactNode
  errorMessage?: string | undefined
  isTableInput?: boolean
  disabled?: boolean
  primary?: boolean
  readOnly?: boolean
  checked?: boolean
  refRegister?: any
  color?: string | undefined
  small?: boolean
  checkmarkAsSvg?: any
}

const RadioButtonWrapper = styled.div<{
  readOnly?: boolean
  color?: string
  small?: boolean
}>`
  display: block;
  align-items: center;
  width: fit-content;
  .pq-radiobutton {
    display: block;
    position: relative;
    cursor: pointer;
    &.-primary {
      border-radius: 10px;
      border: solid 0px rgba(203, 208, 223, 0.3);
      outline: 0;
      padding-left: ${(props) => (!props.small ? '24px' : '16px')};
      font-family: Poppins;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      color: #0d1331;
      cursor: pointer;
    }
    &.-checkmark-svg {
      padding: 0.625em;
      border: solid 2px transparent;

      &.insights {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        & .checkmark-svg {
          display: flex;
          justify-content: center;
          position: static;
          max-width: 92px;
          width: 100%;
          height: fit-content;
          transform: translateY(0);
        }
      }
    }
  }
  .pq-radiobutton-title {
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #0d1331;
    padding-left: ${(props) => (!props.small ? '28px' : '16px')};
    white-space: nowrap;
    &.-disabled {
      opacity: 0.5;
    }
    &.insights {
      padding-left: 0;
    }
  }

  .pq-radiobutton input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: ${(props) => (!props.small ? '24px' : '16px')};
    width: ${(props) => (!props.small ? '24px' : '16px')};
    border-radius: 50%;
    border: solid 2px rgba(203, 208, 223, 0.3);
    background-color: #ffffff;
  }
  .checkmark-svg {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0.625em;
    transform: translateY(-50%);
    height: ${(props) => (!props.small ? '24px' : '16px')};
    width: ${(props) => (!props.small ? '24px' : '16px')};
  }
  .pq-radiobutton:hover input ~ .checkmark {
    ${(props) => !props.readOnly && 'border: solid 2px #3f6db4;'}
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .pq-radiobutton.-disabled:hover input ~ .checkmark {
    ${(props) =>
      !props.readOnly && 'border: solid 2px rgba(203, 208, 223, 0.3);'}
  }

  /* Input based checker (bugged) */

  /* .pq-radiobutton input:checked ~ .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props) => (!props.small ? '24px' : '16px')};
    width: ${(props) => (!props.small ? '24px' : '16px')};
    border-radius: 50%;
    border: solid 2px ${(props) => (!props.color ? '#3f6db4' : props.color)};
    .checkmark-inner {
      display: flex;
      width: ${(props) => (!props.small ? '16px' : '8px')};
      height: ${(props) => (!props.small ? '16px' : '8px')};
      border-radius: 50%;
      border: solid 1px ${(props) => (!props.color ? '#3f6db4' : props.color)};
      background-color: ${(props) => (!props.color ? '#3f6db4' : props.color)};
    }
  }

  .pq-radiobutton.-disabled:hover input:checked ~ .checkmark {
    ${(props) =>
    !props.readOnly && 'border: solid 2px rgba(203, 208, 223, 0.3);'}
  }

 .pq-radiobutton input:checked ~ .checkmark:after {
    display: block;
  } */

  /* Classbased checker */
  .pq-radiobutton .checkmark.checkmark-checked {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props) => (!props.small ? '24px' : '16px')};
    width: ${(props) => (!props.small ? '24px' : '16px')};
    border-radius: 50%;
    border: solid 2px ${(props) => (!props.color ? '#3f6db4' : props.color)};
    .checkmark-inner {
      display: flex;
      width: ${(props) => (!props.small ? '16px' : '8px')};
      height: ${(props) => (!props.small ? '16px' : '8px')};
      border-radius: 50%;
      border: solid 1px ${(props) => (!props.color ? '#3f6db4' : props.color)};
      background-color: ${(props) => (!props.color ? '#3f6db4' : props.color)};
    }
  }
  .pq-radiobutton.-border-checked {
    border: solid 2px #3f6db4;
  }
  .pq-radiobutton .checkmark.checkmark-checked:after {
    display: block;
  }
  .pq-radiobutton.-disabled:hover .checkmark.checkmark-checked {
    ${(props) =>
      !props.readOnly && 'border: solid 2px rgba(203, 208, 223, 0.3);'}
  }
`

export const RadioButton = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<RadioButtonProps>
>((props, ref) => {
  const {
    children,
    className,
    caption,
    id,
    name,
    primary,
    disabled,
    readOnly,
    refRegister,
    checked,
    color,
    small,
    checkmarkAsSvg,
    title,
    ...rest
  } = props

  const classes = classNames(
    'pq-radiobutton',
    (checkmarkAsSvg && checked && '-border-checked') || '',
    (checkmarkAsSvg && '-checkmark-svg') || '',
    (primary && '-primary') || '',
    (disabled && '-disabled') || '',
    className || '',
  )
  const captionClasses = classNames(
    'pq-radiobutton-title',
    (disabled && '-disabled') || '',
    className || '',
  )

  const checkmarkClasses = classNames(
    'checkmark',
    checked ? 'checkmark-checked' : '',
  )
  const checkmarkSvgClasses = classNames(
    'checkmark-svg',
    checked ? 'checkmark-svg-checked' : '',
  )

  const inputRef = useCombinedRefs(ref, refRegister)

  const getCheckmark = () => {
    if (!checkmarkAsSvg) {
      return (
        <span className={checkmarkClasses}>
          <span className="checkmark-inner" />
        </span>
      )
    }
    return <span className={checkmarkSvgClasses}>{checkmarkAsSvg()}</span>
  }

  return (
    <RadioButtonWrapper readOnly={readOnly} color={color} small={small}>
      <label className={classes} htmlFor={id} title={title}>
        <span className={captionClasses} data-cy="pq-radiobutton__title">
          {caption}
        </span>
        <input
          type="radio"
          id={id}
          data-cy="form-radio-input"
          name={name}
          ref={inputRef}
          disabled={disabled}
          readOnly={readOnly}
          checked={checked}
          title={title}
          {...rest}
        />
        {getCheckmark()}
        {children}
      </label>
    </RadioButtonWrapper>
  )
})
