import { css } from '@emotion/react'
import {
  ChangeEvent,
  ComponentProps,
  RefObject,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import SvgMinus from '@static/svg/icons/minus.svg'
import SvgPlus from '@static/svg/icons/plus.svg'
import Button from '@common/components/button/v2'
import { ButtonCss, SvgCss } from './styles'
import { Input } from './input'
import classNames from 'classnames'

const CounterInput = forwardRef(
  (props: ComponentProps<'input'>, inputRef: RefObject<HTMLInputElement>) => {
    const { className, ...restProps } = props

    const classes = classNames('pq-input__counter', className)
    const inputState = useState(
      String(restProps?.defaultValue ?? restProps.min),
    )
    const [inputValue, setInputValue] = inputState

    const value = restProps?.defaultValue ?? restProps?.value
    useEffect(() => {
      if (value) {
        setInputValue(String(value))
      }
    }, [value, setInputValue])

    const handleChangeEvent = (newValue) => {
      if (inputRef.current && props?.onChange) {
        const event = new Event('change', { bubbles: true })
        // Add the new value to the event object
        Object.defineProperty(event, 'target', {
          writable: false,
          value: { ...inputRef.current, value: newValue },
        })

        props?.onChange(event as unknown as ChangeEvent<HTMLInputElement>)
      }
    }

    const onDecrement = (e) => {
      e.stopPropagation()
      if (inputValue > restProps.min) {
        setInputValue((prevValue) => String(parseInt(prevValue, 10) - 1))

        if (inputRef?.current)
          inputRef.current.value = String(parseInt(inputValue, 10) - 1)

        handleChangeEvent(String(parseInt(inputValue, 10) - 1))
      }
    }

    const onIncrement = (e) => {
      e.stopPropagation()
      if (inputValue >= restProps.min) {
        setInputValue((prevValue) => String(parseInt(prevValue, 10) + 1))
        if (inputRef?.current) {
          inputRef.current.value = String(parseInt(inputValue, 10) + 1)
        }

        handleChangeEvent(String(parseInt(inputValue, 10) + 1))
      }
    }

    const handleKeyDown = (event) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        event.preventDefault()
        deleteDigit()
      }
    }

    const deleteDigit = () => {
      const input = inputRef?.current
      const { selectionStart, selectionEnd } = input

      if (selectionStart === selectionEnd) {
        // Delete the digit at the cursor position
        setInputValue((prevValue) => {
          const newValue =
            prevValue.slice(0, selectionStart - 1) +
            prevValue.slice(selectionStart)
          handleChangeEvent(String(newValue))
          return newValue
        })
        input.selectionStart = input.selectionEnd = selectionStart - 1
      } else {
        // Delete the selected digits
        setInputValue((prevValue) => {
          const newValue =
            prevValue.slice(0, selectionStart) + prevValue.slice(selectionEnd)
          handleChangeEvent(String(newValue))
          return newValue
        })
        input.selectionStart = input.selectionEnd = selectionStart
      }
    }

    return (
      <div
        className={classes}
        css={css({
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          zIndex: '999',
        })}
      >
        <Button
          btnTheme="unstyled"
          outlined="none"
          disabled={restProps?.disabled}
          onClick={onDecrement}
          wrapperCss={css`
            ${ButtonCss}
            border-right: transparent;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          `}
        >
          <SvgMinus css={SvgCss} />
        </Button>
        <div onClick={(e) => e.stopPropagation()}>
          <Input
            inputValue={inputValue}
            setInputValue={setInputValue}
            onKeyDown={handleKeyDown}
            {...restProps}
            ref={inputRef}
          />
        </div>
        <Button
          btnTheme="unstyled"
          outlined="none"
          onClick={onIncrement}
          disabled={restProps?.disabled}
          wrapperCss={css`
            ${ButtonCss}
            border-left: transparent;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          `}
        >
          <SvgPlus css={SvgCss} />
        </Button>
      </div>
    )
  },
)

export { CounterInput }
