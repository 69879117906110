import * as styleVars from '@common/styles/constants'

import React, { forwardRef, useEffect } from 'react'
import Select, {
  GroupBase,
  createFilter,
  Props as ReactSelectProps,
} from 'react-select'
import { SelectWrapper, commonStyles } from './styles'

import CreatableSelect from 'react-select/creatable'
import DropdownIndicator from './components/dropdown-indicator'
import { FormElementError } from '../error'
import { ISelectOption } from '../multi-select'
import { PqSelectProps } from './types'
import { capitalizeFirstLetter } from '@common/utils/helpers'
import classNames from 'classnames'
import { css } from '@emotion/react'
import customOption from './components/option-custom'
import styled from '@emotion/styled'
import toolTipSingleValue from './components/single-value-tooltip'
import { useCombinedRefs } from '@common/utils/use-hooks'
import isObject from 'lodash/isObject'
import { useIntl } from '@palqee/intl'

export type IPqSelectProps<T = ISelectOption> = PqSelectProps &
  Partial<ReactSelectProps<T, boolean, GroupBase<T>>>

export type TPqSelectProps = Partial<IPqSelectProps>

export interface IFilterConfig {
  ignoreCase: boolean
  matchFrom: 'start'
}

export const handleSelectStyling = (props?: PqSelectProps) => {
  const tableStyle = {
    ...commonStyles,
    container: (styles) => {
      return {
        ...styles,
        position: 'relative',
        minHeight: '48px',
        height: '100% !important',
      }
    },
    control: (styles) => {
      return {
        ...styles,
        height: 'auto',
        minHeight: '100%',
        boxShadow: '0',
        borderRadius: '8px',
        backgroundColor: 'white',
        color: '#0d1331',
        width: '100%',
      }
    },
    input: (styles) => ({
      ...styles,
      gridArea: '1/1/2/3',
    }),
    placeholder: (styles, { isDisabled }) => {
      const overFlowTooltipStyles = props?.hasTooltip
        ? {
            display: 'inline-block',
            paddingRight: '15px',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }
        : {}
      return {
        ...styles,
        ...overFlowTooltipStyles,
        marginLeft: '2px',
        gridArea: '1/1/2/3',
        color: isDisabled ? styleVars.gray40 : styleVars.gray60,
      }
    },
    menuList: (styles) => ({
      ...styles,
      width: props?.menuWidth ?? '100%',
      paddingTop: '0',
      paddingBottom: '0',
      backgroundColor: styleVars.white,
      opacity: 1,
    }),
    option: (styles) => {
      return {
        ...styles,
        width: props?.menuWidth ?? '100%',
        paddingLeft: '20px',
        fontWeight: 'normal',
        ':hover': {
          fontWeight: '700',
          backgroundColor: styleVars.fadedGrey,
        },
        backgroundColor: styleVars.white,
        color: styleVars.darkBlack,
        cursor: 'pointer',
        borderBottom: `1px solid ${styleVars.fadedWhite};`,
        ':last-child': {
          borderBottom: '0',
        },
      }
    },
  }

  return tableStyle
}

const PqSelect = forwardRef(
  <T extends ISelectOption = ISelectOption>(
    props: IPqSelectProps<T>,
    forwardedRef,
  ) => {
    const intl = useIntl()
    const {
      isCreatable,
      borderRadius,
      inputMarginLeft,
      id,
      name,
      className,
      wrapperCss,
      wrapperClassName,
      disabled,
      caption,
      placeholder = intl.formatMessage({
        id: 'SELECT',
        defaultMessage: 'Select',
      }),
      errorMessage,
      focusError,
      options,
      readOnly,
      value,
      errorBoard,
      isSearchable = false,
      isDropSelect,
      defaultValue,
      refRegister,
      useCustomLabel = false,
      isMulti = false,
      isInline,
      menuPlacement = 'auto',
      shouldUsePortal = false,
      shouldSetInputText = true,
      onKeyDown,
      noOptionsMessage = () => (
        <span
          css={css`
            font-size: 11px;
          `}
        >
          {intl.formatMessage({
            id: 'NO_OPTIONS',
            defaultMessage: 'No options available',
          })}
        </span>
      ),
      customFilterFunc,
      onChange,
      onInputChange,
      onFocus,
      setFocusedOption,
      components,
      hideDropdownIndicator = false,
      styles = {},
      errors,
      ...rest
    } = props

    const wrapperClasses = classNames(
      'pq-select__wrapper',
      wrapperClassName || '',
    )
    const classes = classNames('pq-select', className || '')
    const customStyle = handleSelectStyling(props)

    // using react-select aria-live-messages
    // we can capture selected option
    // and return to parent element
    // https://react-select.com/advanced#accessibility
    const onFocusSelectOption = (focused) => {
      if (setFocusedOption) {
        setFocusedOption(focused?.label)
      }
    }

    const ariaLiveMessages = { onFocus: onFocusSelectOption }

    const [searchInputValue, setSearchInputValue] = React.useState(
      value?.label ?? '',
    )

    const filterConfig: {
      ignoreCase: boolean
      matchFrom: 'start'
    } = {
      ignoreCase: true,
      matchFrom: 'start',
    }

    const setErrorClass = () => {
      let errorClass = ''

      if (!value) {
        if ((!!errors && !!errors[name]) || !!errorMessage || focusError) {
          errorClass = '-error'
        }
      }

      return errorClass
    }

    const boardClasses = classNames(
      'pq-select-input-board',
      ((disabled || readOnly) && '-disabled') || '',
      setErrorClass(),
    )

    const combinedRefs = useCombinedRefs(refRegister, forwardedRef)

    useEffect(() => {
      setSearchInputValue(value?.label ?? '')
    }, [value?.label, setSearchInputValue])

    const setValue = () => {
      if (isObject(value)) {
        if (Array.isArray(value)) {
          return !!(value[0] as any)?.value ? value : null
        }

        return !!(value as any)?.value ? value : null
      }

      return value
    }

    let selectProps: any = {
      ref: combinedRefs,
      menuPortalTarget:
        shouldUsePortal && typeof window !== 'undefined'
          ? document.body
          : undefined,
      menuPlacement,
      onFocus: (e) => {
        if (onFocus) {
          onFocus(e)
        }

        if (shouldSetInputText && value?.label && isCreatable) {
          setSearchInputValue(value.label)
        }
      },
      onKeyDown,
      onChange: (selected, action) => {
        if (onChange) {
          onChange(selected, action)
        }

        if (shouldSetInputText && isCreatable) {
          setSearchInputValue(selected.label)
        }
      },
      id,
      className: classes,
      classNamePrefix: 'pq-select',
      ['aria-labelledby']: 'aria-label',
      ariaLiveMessages,
      formatCreateLabel: (input) => capitalizeFirstLetter(input),
      filterOption: customFilterFunc || createFilter(filterConfig),
      placeholder,
      name,
      options,
      instanceId: name,
      onInputChange: (text) => {
        if (onInputChange) {
          onInputChange(text)
        }

        setSearchInputValue(text)
      },
      defaultValue,
      noOptionsMessage,
      components: {
        DropdownIndicator:
          !hideDropdownIndicator && !isMulti && DropdownIndicator,
        SingleValue: (singleValueProps) => {
          if (useCustomLabel) {
            const {
              data: { label },
            } = singleValueProps
            return <CustomLabelWrapper>{label}</CustomLabelWrapper>
          }
          return toolTipSingleValue(singleValueProps)
        },
        Option: customOption,
        ...components,
      },
      isDropSelect,
      menuIsOpen: readOnly ? false : undefined,
      keepCursorAtEnd: true,
      isDisabled: disabled,
      isSearchable,
      isMulti,
      readOnly,
      value: setValue(),
      ...rest,
    }

    if (isCreatable) {
      selectProps = {
        inputValue: searchInputValue,
        isOverflowed: true,
        styles: {
          ...customStyle,
          dropdownIndicator: (dropdownIndicatorStyles) => ({
            ...dropdownIndicatorStyles,
            display: 'none',
          }),
          ...styles,
        },
        ...selectProps,
      }
    } else {
      selectProps = {
        isDisabled: disabled,
        styles: {
          ...customStyle,
          control: (s) => ({
            ...s,
            minHeight: '48px',
            borderRadius: '8px',
            border: '2px solid',
            flexWrap: 'nowrap',
            boxShadow: 'none',
          }),
          singleValue: (s) => ({
            ...s,
            display: 'flex',
            alignItems: 'center',
          }),
          ...styles,
        },
        ...selectProps,
      }
    }

    const renderSelectType = () => {
      if (isCreatable) {
        return <CreatableSelect {...selectProps} />
      }

      return <Select {...selectProps} />
    }

    const titleClasses = classNames(
      'pq-select-input-title',
      (disabled && '-disabled') || '',
    )
    return (
      <SelectWrapper
        className={wrapperClasses}
        data-cy="pq-select__wrapper"
        style={{ borderRadius }}
        inputMarginLeft={inputMarginLeft}
        readOnly={readOnly}
        isInline={isInline}
        css={wrapperCss}
      >
        <div className={boardClasses} data-cy="form-pq-select">
          {caption && (
            <span className={titleClasses} data-cy="pq-select__input-title">
              {' '}
              {`${caption}`}
            </span>
          )}
          {renderSelectType()}
        </div>
        <FormElementError
          showErrorBoard={errorBoard}
          name={name}
          errors={errors}
          errorMessage={errorMessage}
        />
      </SelectWrapper>
    )
  },
)

export const CustomLabelWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

export default PqSelect
